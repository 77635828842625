import { css } from 'react-emotion';

const padding = '.6rem 1.125rem';

const DefaultStyles = css`
  margin-right: 1em;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-transform: uppercase;

  &:after {
    content: none;
  }

  a {
    display: inline-block;
    font-size: 0.75rem;
    text-transform: uppercase;

    text-decoration: none;
    padding: ${padding};
    color: #000;
  }
`;

const StyleBreadcrumbProd = props => css`
  ${props.theme.below.md} {
    margin-bottom: 1rem;
  }

  li {
    ${DefaultStyles}

    a {
      background: ${props.theme.colors.themeGrey};
    }

    &:nth-last-of-type(2) {
      & > a {
        background: ${props.theme.colors.themeBlack};
        margin-right: 0;
        color: #fff;
      }
    }
    &:last-of-type {
      display: none;
    }
  }
`;

const StyleBreadcrumb = props => css`
  li {
    ${DefaultStyles}
    a {
      background: ${props.theme.colors.themeGrey};
    }
    &:last-of-type {
      padding: ${padding};
      background: ${props.theme.colors.themeBlack};
      margin-right: 0;
      color: #fff;
    }
  }
`;

export { StyleBreadcrumbProd, StyleBreadcrumb };
