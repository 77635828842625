import PaginationContext from '@jetshop/core/components/Pagination/PaginationContext';
import t from '@jetshop/intl';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { PaginationBehaviour } from '@jetshop/ui/Pagination';
//import { Pagination, PaginationBehaviour } from '@jetshop/ui/Pagination';
//import { PaginationWrapper } from '@jetshop/ui/Pagination/Pagination';
import ActiveListFilters from '@jetshop/ui/Filter/ActiveFilters';
import SortOrderDropdown from '@jetshop/ui/SortOrder/SortOrderDropdown';
import React, { Component, Fragment } from 'react';
import styled from 'react-emotion';
import posed from 'react-pose';
import CategoryHeader from '../CategoryPage/CategoryHeader';
import MaxWidth from '../Layout/MaxWidth';
import CategoryHeaderLoadingState from './CategoryHeaderLoadingState';
import Filters from './Filters';
import ProductGrid from './ProductGrid';

import PaginationProvider from '../Theme/Pagination/Pagination';
import PaginationControlls from '../Theme/Pagination/PaginationControlls';

const Container = styled(MaxWidth)`
  padding-top: 2rem;
  ${({ theme }) => theme.above.sm} {
    padding-top: 2rem;
  }
`;

const ProductGridNoFilter = styled(ProductGrid)`
  > div {
    width: 25%;
    ${({ theme }) => theme.below.md} {
      width: 50%;
    }
  }
`;

const CategoryWrapper = styled('div')``;

const ProductNumber = styled('p')`
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  ${({ theme }) => theme.below.sm} {
    width: 100%;
    text-align: center;
  }
`;

// Expand the filters out from 0 height to full height once they load
const FilterAnimation = posed.div({
  closed: {
    height: 0,
    opacity: 0,
    overflow: 'hidden'
  },
  open: {
    height: 'auto',
    opacity: 1,
    duration: 600,
    applyAtEnd: { overflow: 'visible' }
  }
});

// Fade in the product grid once it loads
const ContainerAnimation = posed.div({
  closed: {
    opacity: 0
  },
  open: { opacity: 1 }
});

// This wraps the filters and the product grid and staggers their animation.
// So the FilterAnimation will run first, and then 200ms later the
// ContainerAnimation will run.
const StaggerAnimation = posed.div({
  open: { staggerChildren: 200 }
});

const CategoryContentWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.below.md} {
    display: block;
  }
`;

const FilterWrapper = styled('div')`
  flex: 0 1 25%;
  padding-right: 4%;
  ${({ theme }) => theme.below.md} {
    padding-right: 0;
  }
`;

const ProductWrapper = styled('div')`
  flex: 1 1 75%;
  padding-left: 4%;
  ${({ theme }) => theme.below.md} {
    padding-left: 0;
  }
`;

const ProductWrapperNoFilter = styled(ProductWrapper)`
  padding-left: 0;
`;

const ResultRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space[3]};

  label,
  span,
  button {
    display: inline-flex;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.4em 0.75em;
    margin: 0 0.5rem 0 0;
  }

  label {
    margin-right: 0;
    padding-right: 0;
    color: ${({ theme }) => theme.colors.themeGreyDark};
    &:after {
      display: inline;
      content: ':';
    }
  }

  button {
    outline: none;
    margin-top: 0;
    margin-bottom: 0;
    svg {
    }
  }
`;

const ResultLeft = styled('div')`
  aside {
    margin: 0;
    > div {
      order: 2;
      color: ${({ theme }) => theme.colors.themeBlack};
      > button {
        max-height: 48px;
        background: ${({ theme }) => theme.colors.themeGrey};
        color: #000;
        padding: 0 0.75rem;
        svg use {
          fill: ${({ theme }) => theme.colors.themeGreyDark};
        }
      }
    }
    > button {
      max-height: 48px;
      order: 1;
      margin: 0;
      background: ${({ theme }) => theme.colors.themeGreyDark};
      color: #fff;
      text-decoration: none;
      margin: 0 0.5rem 0.5rem 0;
    }
  }
`;

const ResultRight = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  h4 {
    font-size: 1rem;
    text-transform: none;
  }

  > p {
    margin: 0;
    margin-right: ${({ theme }) => theme.space[5]};
    font-size: 1rem;
    white-space: nowrap;
  }

  > div {
    margin: 0;
    background: transparent;

    > button {
      border: none !important;
      height: auto;
      padding: 0;
    }

    > div {
      min-width: 210px;
      right: 0;
      top: 35px;
      > div {
        overflow: hidden;
        border-top: 1px solid #dedede;
        > button {
          span {
            margin: 0;
            padding: 0;
            text-align: left;
          }
        }
      }
    }
  }
`;

class Category extends Component {
  render() {
    const {
      category,
      cacheHit,
      result: { loading, data }
    } = this.props;

    let GlobalFiltersFragment = Fragment;
    let catComponentProps;

    const DynamicProductGrid =
      category && category.products.filters.length
        ? ProductGrid
        : ProductGridNoFilter;
    const DynamicProductWrapper = category.products.filters.length
      ? ProductWrapper
      : ProductWrapperNoFilter;

    if (data.route && data.route.slug === 'accessories') {
      //GlobalFiltersFragment = GlobalFilters;
      //catComponentProps = { defaultFilters: {listFilters: [{id: 'gender', values: ['Unisex'] }] } }
    }

    return (
      <CategoryWrapper>
        <GlobalFiltersFragment {...catComponentProps}>
          <>
            {!cacheHit ? (
              // Show a dummy header if we don't have cached data
              <CategoryHeaderLoadingState />
            ) : (
              <CategoryHeader
                category={category}
                parents={data.route.parents}
              />
            )}

            <Container>
              {/*
            <Above breakpoint="sm">
              {matches =>
                matches && <SectionHeader>{t('Products')}</SectionHeader>
              }
            </Above>
            */}

              <StaggerAnimation pose={cacheHit && 'open'}>
                <CategoryContentWrapper>
                  {category.products.filters.length ? (
                    <FilterWrapper>
                      <FilterAnimation pose={cacheHit ? 'open' : 'closed'}>
                        <Filters
                          filters={category.products.filters || []}
                          sortOrders={category.products.sortOrders || []}
                          result={this.props.result}
                        />
                      </FilterAnimation>
                    </FilterWrapper>
                  ) : (
                    ''
                  )}
                  <DynamicProductWrapper>
                    <ContainerAnimation pose={cacheHit ? 'open' : 'closed'}>
                      <ResultRow>
                        <Above
                          breakpoint="sm"
                          render={() => (
                            <>
                              {/*
                            filters={ data.route.parents[0].id === 'accessories' ? [{ id: 'gender', values: ['Men', 'Unisex'] }] : category.products.filters }
                            */}
                              <ResultLeft>
                                <ActiveListFilters
                                  filters={category.products.filters}
                                />
                              </ResultLeft>
                              <ResultRight>
                                <ProductNumber>
                                  {category.products.totalResults &&
                                    t('{totalProducts} Products', {
                                      totalProducts:
                                        category.products.totalResults
                                    })}
                                </ProductNumber>
                                {category.products.sortOrders &&
                                  category.products.sortOrders.length > 0 && (
                                    <SortOrderDropdown
                                      sortOrders={category.products.sortOrders}
                                    />
                                  )}
                              </ResultRight>
                            </>
                          )}
                        />
                        <Below
                          breakpoint="sm"
                          render={() => (
                            <ProductNumber>
                              {t('{totalProducts} Products', {
                                totalProducts: category.products.totalResults
                              })}
                            </ProductNumber>
                          )}
                        />
                      </ResultRow>
                      <DynamicProductGrid
                        products={category.products.result}
                        listName={category.name}
                        categoryPath={data.route}
                        loading={loading}
                      />
                    </ContainerAnimation>
                  </DynamicProductWrapper>
                </CategoryContentWrapper>
              </StaggerAnimation>

              {category.products.totalResults > 0 && (
                /*
                <PaginationProvider>
                  <PaginationContext.Consumer>
                    {({ currentPage, goToPage, perPage }) => (
                      <>
                      {console.log("perPage", perPage)}
                        <PaginationBehaviour
                          currentPage={currentPage}
                          goToPage={goToPage}
                          perPage={perPage}
                          total={category.products.totalResults}
                        >
                          {props => {
                            return <PaginationControlls isFlight {...props} />;
                          }}
                        </PaginationBehaviour>
                      </>
                    )}
                  </PaginationContext.Consumer>
                </PaginationProvider>
                */
                <PaginationProvider>
                  <PaginationContext.Consumer>
                    {({
                      currentPage,
                      goToPage,
                      perPage,
                      setProductsPerPage
                    }) => (
                      <Fragment>
                        {perPage !== 24 && setProductsPerPage(24)}
                        <PaginationBehaviour
                          currentPage={currentPage}
                          goToPage={goToPage}
                          perPage={perPage}
                          total={category.products.totalResults}
                          setProductsPerPage={setProductsPerPage}
                        >
                          {props => {
                            return (
                              props.totalPages > 1 && (
                                <PaginationControlls isFlight {...props} />
                              )
                            );
                          }}
                        </PaginationBehaviour>
                        <div id="filter-stop" />
                      </Fragment>
                    )}
                  </PaginationContext.Consumer>
                </PaginationProvider>
              )}
            </Container>
          </>
        </GlobalFiltersFragment>
      </CategoryWrapper>
    );
  }
}

export default Category;
