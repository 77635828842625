import FilterContext from '@jetshop/core/components/Filters/FilterContext';
import ErrorBoundary from '@jetshop/ui/ErrorBoundary/Generic';
import PassThrough from '@jetshop/ui/ErrorBoundary/PassThrough';
import MultiSelectList from '@jetshop/ui/Select/SelectComponents/MultiSelectList';
import SelectDropdown from '@jetshop/ui/Select/SelectDropdown';
import React from 'react';
import styled from 'react-emotion';
import stripCategoryId from '@jetshop/core/components/Filters/utils/stripCategoryId';

//import { ReactComponent as BoxChecked } from '../../svg/BoxChecked.svg';
import { ReactComponent as Arrow } from '../../svg/Arrow.svg';

const StyledSelectDropdown = styled(SelectDropdown)`
  margin-bottom: ${({ theme }) => theme.space[3]};

  > button {
    &[aria-expanded='true'] {
      svg {
        transform: rotate(180deg);
      }
      + div {
        transition: max-height 5s ease-in-out;
      }
    }
  }

  > div {
    position: relative;
    transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
    > div {
      margin-top: 1rem;
      border: none;
      max-height: none;
    }

    button {
      justify-content: flex-start;
      align-items: center;
      position: relative;
      margin-bottom: 0.75rem;

      &:before {
        content: '';
        width: 18px;
        height: 18px;
        border: 1px solid #000;
        position: absolute;
        top: 0;
        left: 0;
      }

      p {
        margin-left: 0;
        &:nth-of-type(2) {
          display: none;
        }
      }

      > span {
        margin-left: 38px;
        order: 2;
      }
      > svg {
        order: 1;
        width: 18px;
        height: 18px;
        position: absolute;
        top: 0;
        left: 0;
        padding: 2px;
        transform: rotate(0deg);
        background: #000;
        use {
          fill: white;
        }
      }
    }
  }

  ${({ theme }) => theme.below.sm} {
    border-top: none;
    margin: 0;
    .toggleSelect {
      border-top: 0;
    }
  }
`;

// Maps over list filters and returns a set of Downshift-powered dropdowns
const ListFilterFlyouts = ({ filters, className = '' }) => {
  const listFilters = filters.filter(
    filter => filter.__typename === 'ListFilter'
  );
  if (listFilters.length < 1) return null;
  return React.createElement(
    ErrorBoundary,
    { component: PassThrough },
    React.createElement(FilterContext.Consumer, null, filterProps =>
      listFilters.map(filter => {
        const selectedFilter = filterProps.activeFilters.listFilters.find(
          item => item.id === stripCategoryId(filter.id)
        );
        const selectedItems =
          selectedFilter && selectedFilter.values.map(val => ({ value: val }));
        const onChange = vals => {
          return filterProps.applyListFilter(filter.id, vals);
        };
        return React.createElement(StyledSelectDropdown, {
          CustomCarrot: Arrow,
          CustomCheck: Arrow,
          key: filter.name,
          items: filter.items,
          title: filter.name,
          multiSelect: true,
          SelectComponent: MultiSelectList,
          selectedItems: selectedItems,
          onChange: vals => onChange(vals),
          className: className
        });
      })
    )
  );
};
export default ListFilterFlyouts;

