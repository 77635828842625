import ProductGrid from '../Theme/ProductGrid';
import styled from 'react-emotion';

const StyledProductGrid = styled(ProductGrid)`
  > div {
    width: 33.333%;
    ${({ theme }) => theme.below.md} {
      width: 50%;
    }
  }
`;

export default StyledProductGrid;
