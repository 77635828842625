import React, { PureComponent } from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';
import { withPagination } from './Pagination';
import { ReactComponent as ArrowSharpSVG } from '../../../svg/ArrowSharp.svg';

const ArrowSharp = styled(ArrowSharpSVG)`
  width: 7px;
  height: 12px;
`;

const ArrowLeft = styled('span')`
  padding: 0.5rem 1.25rem;
`;

const ArrowRight = styled('span')`
  padding: 0.5rem 1.25rem;
  ${ArrowSharp} {
    transform: rotate(180deg);
  }
`;

const Button = styled(Link)`
  display: inline-block;

  &[disabled] {
    cursor: default;
  }
`;

const Dots = styled('span')`
  margin: 2px;
`;

const PageNumbers = styled('div')`
  display: inline-block;
`;

const PageLink = styled(Link)`
  cursor: pointer;
  font-style: normal;
  text-decoration: none;
  padding: 11px 18px;
  color: #000;
  &.active {
    background: #000;
    color: #fff;
  }
`;

const PaginationContainer = styled('div')`
  display: inline-flex;
  align-items: center;
  height: 40px;
  border: 1px solid #000;
`;

const PaginationOuter = styled('div')`
  text-align: center;
  margin: 32px 0;

  ${({ theme }) => theme.below.md} {
    margin: 0;
  }
`;

class PaginationControlls extends PureComponent {
  skipPageNumber = (page, currentPage) =>
    currentPage > page + 6 || currentPage < page - 6;

  linkEvent(event, page) {
    event.preventDefault();

    if (event.target.getAttribute('disabled') !== null) {
      event.stopPropagation();
      return false;
    }

    if (this.props.isFlight) {
      this.props.goToPage(page);
      return;
    }

    this.props.updatePage({ page: page });
  }

  render() {
    const { pagination } = this.props;

    if (this.props.isFlight) {
      pagination.page = this.props.currentPage;
      pagination.totalPages = this.props.totalPages;
    }

    const { page, totalPages } = pagination;

    let dotsAdded = false;
    let PageButtons = [];
    for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
      let skipPage = true;

      if (pageNum === 1 || pageNum === totalPages) {
        skipPage = false;
      }

      if (skipPage && this.skipPageNumber(pageNum, page)) {
        if (!dotsAdded) {
          dotsAdded = true;
          PageButtons.push(<Dots key={pageNum}>...</Dots>);
        }
        continue;
      }
      dotsAdded = false;

      PageButtons.push(
        <PageLink
          key={pageNum}
          onClick={event => this.linkEvent(event, pageNum)}
          className={page === pageNum ? 'active' : ''}
          disabled={page === pageNum}
          to={pagination.getPageUrl(pageNum)}
        >
          {pageNum}
        </PageLink>
      );
    }

    return (
      totalPages > 0 && (
        <PaginationOuter>
          <PaginationContainer>
            <Button
              onClick={event => this.linkEvent(event, page - 1)}
              disabled={page === 1}
              to={pagination.getPageUrl(page - 1)}
            >
              <ArrowLeft>
                <ArrowSharp />
              </ArrowLeft>
            </Button>
            <PageNumbers>{PageButtons.map(Button => Button)}</PageNumbers>
            <Button
              onClick={event => this.linkEvent(event, page + 1)}
              to={pagination.getPageUrl(page + 1)}
              disabled={page === totalPages}
            >
              <ArrowRight>
                <ArrowSharp />
              </ArrowRight>
            </Button>
            {/*
            <PageDescription>
              {t('Page {page} of {totalPages}', { page, totalPages })}
            </PageDescription>
            */}
          </PaginationContainer>
        </PaginationOuter>
      )
    );
  }
}

export default withPagination(PaginationControlls);
