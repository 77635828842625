import t from '@jetshop/intl';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import BoolFilters from '@jetshop/ui/Filter/FilterTypes/BoolFilters';
import ListFilterFlyouts from '../../Theme/CustomListFilterFlyouts';
import MultiListFlyouts from '@jetshop/ui/Filter/FilterTypes/Multi/MultiListFlyouts';
//import RangeFilterFlyouts from '@jetshop/ui/Filter/FilterTypes/Range/RangeFilterFlyouts';
import CustomRangeFilterFlyouts from '../../Theme/CustomRangeFilterFlyouts';
import Drawer, { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
//import SortOrderDropdown from '@jetshop/ui/SortOrder/SortOrderDropdown';
import { ReactComponent as Carrot } from '@jetshop/ui/svg/Carrot.svg';
import theme from '@jetshop/ui/utils/theme';
import get from 'lodash.get';
import React, { Component, Fragment } from 'react';
import styled from 'react-emotion';
import FilterDrawer from './FilterDrawer';

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  > button {
    height: auto;
    width: 100%;
    background: ${({ theme }) => theme.colors.themeGrey};
    border: none;
    outline: none;
    text-align: left;
    > p {
      padding: 0.5rem 0;
      padding-left: 1rem;
    }
  }
  button:first-of-type {
    margin-bottom: 1rem;
  }
  ${theme('above.sm')} {
    padding: ${theme('space.1')};
  }
  svg {
    display: inline-block;
    transform: rotate(-90deg);
    align-self: center;
  }
`;

const MobileButton = styled('button')`
  height: 40px;
  background: #ffffff;
  border: 1px solid #cccccc;
  width: 50%;
  cursor: pointer;
  text-align: left;
  padding: ${theme('space.1')};
  font-weight: ${theme('fontWeights.semibold')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Filters = styled('div')`
  width: 100%;

  h4 {
    font-size: 1.125rem;
    margin-left: 0;
  }

  p {
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.themeBlack};
    font-size: 1rem;
  }

  button {
    height: auto;
    background: none;
    border: none !important;
    outline: none;
    background: none !important;
    color: ${({ theme }) => theme.colors.themeBlack} !important;
  }
`;

// const FlyoutWrapper = styled('div')`
//   > div {
//     width: 80% !important;
//   }
// `;

class Filter extends Component {
  render() {
    const { filters } = this.props;
    const sortOrders = get(this, 'props.sortOrders', []);

    return (
      <Fragment>
        <Above
          breakpoint="sm"
          render={() => (
            <Fragment>
              <Filters>
                <ListFilterFlyouts filters={filters} />
                <CustomRangeFilterFlyouts filters={filters} />
              </Filters>
              <Filters>
                <MultiListFlyouts
                  filters={filters}
                  result={this.props.result}
                />
                <div
                  css={{
                    marginLeft: 'auto',
                    marginRight: '8px'
                  }}
                >
                  <BoolFilters filters={filters} />
                </div>
              </Filters>
            </Fragment>
          )}
        />
        <Below
          breakpoint="sm"
          render={() => {
            return (
              <Fragment>
                <ButtonWrapper>
                  <DrawerTrigger id="filterDrawer" showCover={false}>
                    {({ showTarget }) => (
                      <MobileButton onClick={showTarget}>
                        <p>{t(' Filter')}</p>
                        <Carrot />
                      </MobileButton>
                    )}
                  </DrawerTrigger>

                  {sortOrders.length > 0 && (
                    <DrawerTrigger id="sortDrawer" showCover={false}>
                      {({ showTarget }) => (
                        <MobileButton onClick={showTarget}>
                          <p>{t('Sort By')}</p>
                          <Carrot />
                        </MobileButton>
                      )}
                    </DrawerTrigger>
                  )}
                </ButtonWrapper>

                <DrawerTarget id="filterDrawer">
                  {({ hideTarget, isOpen }) => (
                    <Drawer isOpen={isOpen} right>
                      <FilterDrawer close={hideTarget} filters={filters} />
                    </Drawer>
                  )}
                </DrawerTarget>

                {sortOrders.length > 0 && (
                  <DrawerTarget id="sortDrawer">
                    {({ hideTarget, isOpen }) => (
                      <Drawer isOpen={isOpen} right>
                        <FilterDrawer
                          close={hideTarget}
                          sortOrders={sortOrders}
                        />
                      </Drawer>
                    )}
                  </DrawerTarget>
                )}
              </Fragment>
            );
          }}
        />
      </Fragment>
    );
  }
}

export default Filter;
