import Analytics from '@jetshop/core/analytics/Analytics';
import { trackList } from '@jetshop/core/analytics/tracking';
import GridProduct from './GridProduct';
import React, { Fragment } from 'react';
import styled from 'react-emotion';

const ProductGridContainer = styled('div') `
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  //margin-right: -${({ theme }) => theme.space[1]};
  //margin-left: -${({ theme }) => theme.space[1]};
`;
const productPlaceholder = {
    name: '',
    id: 'placeholder',
    images: []
};

const ProductGrid = ({ className = '', products, loading, listName, categoryPath = null, ProductComponent = GridProduct }) => {
    const productList = products.length === 0 && loading
        ? Array(4).fill(productPlaceholder)
        : products;
    return (React.createElement(ProductGridContainer, { "data-testid": "product-grid", className: className }, products && (React.createElement(Fragment, null,
        !loading ? (React.createElement(Analytics, { track: trackList({
                listName,
                products: products
            }) })) : null,
        productList.map((product, index) => (React.createElement(ProductComponent, { loading: loading, key: `${index}${product.id}`, product: product, categoryPath: categoryPath })))))));
};
export default ProductGrid;

