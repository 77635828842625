import RangeFilter from '@jetshop/ui/Filter/FilterTypes/Range/RangeFilter';
import RangeFiltersBehaviour from '@jetshop/ui/Filter/FilterTypes/Range/RangeFiltersBehaviour';
import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import {
  ListItem,
  ListTitle,
  StyledCheck
} from '@jetshop/ui/Select/Components';
import React from 'react';
import styled from 'react-emotion';

import { ReactComponent as Arrow } from '../../svg/Arrow.svg';

const StyledListItem = styled(ListItem)`
  align-self: center;
  flex: 0.5 1 220px;
`;
const StyledRangeFilter = styled(RangeFilter)`
  position: absolute;
  .handle {
    border-radius: 50%;
  }
`;
const RangeFilterContainer = styled('div')`
  position: relative;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
  & > div {
    border-top: 1px solid #dedede;
    padding-top: 0.5rem;
  }
  div {
    margin-top: 0.5rem;
    button {
      background: ${({ theme }) => theme.colors.themeBlack} !important;
      color: #fff !important;
    }
  }
`;
const RangeFilterFlyouts = ({ filters }) => {
  return React.createElement(
    RangeFiltersBehaviour,
    { filters: filters },
    ({ selectedVals, filterProps: { applyRangeFilter }, filter }) => {
      return React.createElement(
        RangeFilterContainer,
        null,
        React.createElement(
          FlyoutTrigger,
          {
            showCover: false,
            coverStyles: { background: 'transparent' },
            id: `range-${filter.id}`
          },
          ({ showTarget, hideTarget, isOpen }) =>
            React.createElement(
              StyledListItem,
              { key: filter.id, onClick: isOpen ? hideTarget : showTarget },
              React.createElement(ListTitle, null, filter.name),
              selectedVals
                ? React.createElement(StyledCheck, null)
                : React.createElement(Arrow, { open: isOpen })
            )
        ),
        React.createElement(
          FlyoutTarget,
          { id: `range-${filter.id}` },
          ({ isOpen, hideTarget }) =>
            isOpen
              ? React.createElement(StyledRangeFilter, {
                  onSubmit: hideTarget,
                  applyRangeFilter: applyRangeFilter,
                  defaultVal: selectedVals,
                  min: filter.min,
                  max: filter.max,
                  id: filter.id,
                  key: filter.min + filter.max
                })
              : null
        )
      );
    }
  );
};
RangeFilterFlyouts.Container = RangeFilterContainer;
export default RangeFilterFlyouts;

