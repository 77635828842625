import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

const PaginationContext = React.createContext();

class PaginationProvider extends PureComponent {
  pagination = {
    values: {
      page: 1,
      perPage: 20,
      totalItems: 0,
      totalPages: 0
    },
    functions: {
      gotoPage: newPage => {
        const { page } = this.pagination.values;
        if (page === newPage) {
          return;
        }
        this.pagination.values.page = newPage;
        this.setState({ currentPage: newPage });
      },
      setTotalItems: newTotalItems => {
        const { totalItems } = this.pagination.values;
        if (newTotalItems <= 0 || totalItems === newTotalItems) {
          return;
        }

        this.pagination.values = {
          ...this.pagination.values,
          totalItems: newTotalItems,
          totalPages: Math.ceil(newTotalItems / this.pagination.values.perPage)
        };

        if (this.pagination.values.totalPages > 0) {
          this.setState({ currentPage: this.pagination.values.perPage });
        }
      },
      getPageUrl: page => {
        const { location } = this.props;
        const params = qs.parse(location.search.substring(1));
        params['page'] = page;
        return location.pathname + '?' + qs.stringify(params);
      }
    }
  };

  constructor(props) {
    super(props);
    const defaults = this.pagination.values;
    const { perPage, page } = props;
    this.state = {
      currentPage: 1
    };
    this.pagination.values = {
      ...defaults,
      page: page ? page : defaults.page,
      perPage: perPage ? perPage : defaults.perPage
    };
  }

  componentWillUpdate(props) {
    const { perPage, page } = props;
    const defaults = this.pagination.values;
    this.pagination.values = {
      ...defaults,
      page: page ? page : defaults.page,
      perPage: perPage ? perPage : defaults.perPage
    };
  }

  render() {
    const { children } = this.props;
    return (
      <PaginationContext.Provider value={{ ...this.pagination }}>
        {children}
      </PaginationContext.Provider>
    );
  }
}

export default withRouter(PaginationProvider);

export const Pagination = ({ children }) => {
  return (
    <PaginationContext.Consumer>
      {pagination => {
        return children(pagination.values);
      }}
    </PaginationContext.Consumer>
  );
};

export const withPagination = Components => {
  return props => {
    return (
      <PaginationContext.Consumer>
        {pagination => {
          return (
            <React.Fragment>
              {
                <Components
                  {...props}
                  pagination={
                    pagination && {
                      ...pagination.values,
                      ...pagination.functions
                    }
                  }
                />
              }
            </React.Fragment>
          );
        }}
      </PaginationContext.Consumer>
    );
  };
};
