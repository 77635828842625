import { default as Breadcrumbs } from '@jetshop/ui/Breadcrumbs';
import React from 'react';
import styled from 'react-emotion';
import Image from '@jetshop/ui/Image/Image';
import MaxWidth from '../Layout/MaxWidth';
import { StyleBreadcrumb } from '../Theme/StyleBreadcrumb';
import { Above, Below } from '@jetshop/ui/Breakpoints';

import { ReactComponent as LogoSafety } from '../../images/logo-safety.svg';
import { ReactComponent as LogoPowerboots } from '../../images/logo-powerboots-color.svg';
import { ReactComponent as LogoAlpina } from '../../images/logo-alpina-color.svg';
import { ReactComponent as LogoSulman } from '../../images/logo-sulman.svg';

const StyledMaxWidth = styled(MaxWidth)`
  display: block;
  height: 100%;
  position: relative;
  ${({ theme }) => theme.below.md} {
    padding: 0 0.75rem;
  }
`;

export const CategoryHeaderWrapper = styled('div')`
  position: relative;
  // height: calc(264px + ${({ theme }) => theme.sizes.topBarHeight});
  margin-top: -${({ theme }) => theme.sizes.topBarHeight};
  padding-top: ${({ theme }) => theme.sizes.topBarHeight};
  padding-bottom: ${({ theme }) => theme.space[5]};
  position: relative;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;

  ${({ theme }) => theme.below.md} {
    padding-top: 0;
    padding-bottom: ${({ theme }) => theme.sizes.topBarHeight};
    height: calc(304px + ${({ theme }) => theme.sizes.topBarHeight});
  }
`;

export const CategoryHeaderBackground = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
`;

const CategoryName = styled('h1')`
  ${({ theme }) => theme.typography.h1}
  display: inline-block;
  position: relative;
  margin-bottom: 48px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 800;
  letter-spacing: 1px;
  ${({ theme }) => theme.below.md} {
    color: #fff;
  }
  &:after {
    content: '';
    width: calc(100% - 20px);
    height: 7px;
    max-width: 200px;
    position: absolute;
    bottom: -1.25rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: ${({ theme }) => theme.colors.black};
    ${({ theme }) => theme.below.md} {
      background: #fff;
    }
  }

  &.hide {
    display: none;
  }
`;

const CategoryContent = styled('div')`
  max-width: 540px;
  font-weight: 300;
  line-height: 26px;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.below.md} {
    color: #fff;
  }
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  // position: absolute;
  // top: ${({ theme }) => theme.space[3]};
  // left: 2rem;
  ${StyleBreadcrumb}

  ${({ theme }) => theme.below.md} {
    position: absolute;
    top: auto;
    bottom: -${({ theme }) => theme.space[4]};
    left: ${({ theme }) => theme.space[4]};
    margin-bottom: 0;
    z-index: 3;
  }
`;

const CategoryBannerImage = styled(Image)`
  /* height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  background-position: center bottom; */
  // height: 286px;
  // width: 100%;
`;

const CategoryInfoInner = styled('div')`
  width: 100%;
  // max-width: 420px;
  max-width: 460px;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.space[2]};

  .logo-wrapper {
    display: flex;
  }
`;

const CategoryInfo = styled('div')`
  width: 50%;
  flex: 0 1 50%;
  justify-content: center;
  // align-self: center;
  align-self: flex-start;
  ${({ theme }) => theme.below.md} {
    flex: 0 1 auto;
    width: 100%;
    height: 100%;
    min-height: 290px;
    padding: ${({ theme }) => theme.space[3]} 0;
    order: 2;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
  }
`;

const CategoryImageContainer = styled('div')`
  width: 50%;
  flex: 0 1 50%;
  min-height: 290px;
  ${({ theme }) => theme.below.md} {
    flex: 0 1 auto;
    order: 1;
    width: 100%;
    min-height: 340px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 !important;
  }
`;

const CategoryInnerContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.below.md} {
    position: relative;
    flex-direction: column;
    margin-top: 30px;
  }
`;

const LogoWrapper = styled('div')`
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
  margin-bottom: 2rem;
  ${({ theme }) => theme.below.md} {
    margin-top: 1rem;
    margin-bottom: 0;
  }
`;

const CategoryHeader = ({ category, parents }) => {
  const breadcrumbProps = {
    breadcrumbText: category.breadcrumbText,
    parents
  };
  //sizes={[1 / 5]}
  // src={`${category.images.pop().url}`}

  const shouldHideHeader = header => {
    return header === 'Sulmanin' || header === 'Alpina' || header === 'Sulman';
  };

  return (
    <>
      <CategoryHeaderWrapper>
        <StyledMaxWidth>
          <StyledBreadcrumbs {...breadcrumbProps} />
          <CategoryInnerContainer>
            <CategoryInfo>
              <CategoryInfoInner>
                <Above breakpoint="md">
                  {matches =>
                    matches && (
                      <>
                        {category.id === 173 && (
                          <LogoWrapper>
                            <LogoSafety />
                          </LogoWrapper>
                        )}
                        {category.id === 174 && (
                          <LogoWrapper>
                            <LogoPowerboots />
                          </LogoWrapper>
                        )}
                        {category.id === 175 && (
                          <LogoWrapper>
                            <LogoAlpina />
                          </LogoWrapper>
                        )}
                        {category.id === 176 && (
                          <>
                            <div class="logo-wrapper">
                              <LogoWrapper>
                                <LogoSulman />
                              </LogoWrapper>
                              <LogoWrapper>
                                <LogoAlpina />
                              </LogoWrapper>
                            </div>
                          </>
                        )}
                      </>
                    )
                  }
                </Above>
                {category && category.name && (
                  <CategoryName
                    data-testid="page-header"
                    className={shouldHideHeader(category.name) ? 'hide' : null}
                  >
                    {category.name}
                  </CategoryName>
                )}
                <CategoryContent
                  data-testid="category-description"
                  dangerouslySetInnerHTML={{
                    __html: category.content
                  }}
                />
              </CategoryInfoInner>
            </CategoryInfo>
            <CategoryImageContainer>
              {category.images.length > 0 && (
                <Above breakpoint="md">
                  {matches =>
                    matches ? (
                      <CategoryBannerImage
                        src={category.images}
                        fillAvailableSpace={true}
                        aspect="61:28"
                        alt={category.name}
                        quality={90}
                      />
                    ) : (
                      <>
                        <CategoryBannerImage
                          src={category.images}
                          fillAvailableSpace={true}
                          sizes={[1.75, 1.25, 1, 1 / 2, 600]}
                          aspect="5:4"
                          alt={category.name}
                        />
                        <CategoryHeaderBackground />
                      </>
                    )
                  }
                </Above>
              )}
            </CategoryImageContainer>
          </CategoryInnerContainer>
        </StyledMaxWidth>
      </CategoryHeaderWrapper>
      {category.id === 174 && (
        <Below breakpoint="md">
          {matches =>
            matches && (
              <LogoWrapper>
                <LogoPowerboots />
              </LogoWrapper>
            )
          }
        </Below>
      )}
    </>
  );
};

export default CategoryHeader;
