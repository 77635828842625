import { LoadingLine } from '@jetshop/ui/Loading/LoadingLine';
import React from 'react';
import { css } from 'react-emotion';
import MaxWidth from '../Layout/MaxWidth';
import { CategoryHeaderWrapper } from './CategoryHeader';

export default React.memo(function CategoryHeaderLoadingState() {
  const backgroundColor = '#e2e2e2';
  return (
    <CategoryHeaderWrapper>
      <MaxWidth>
        <LoadingLine
          color={backgroundColor}
          heightPx={14}
          className={css`
            margin-bottom: 2rem;
          `}
        />
        <LoadingLine
          color={backgroundColor}
          widthRem={10}
          heightPx={27}
          className={css`
            margin-bottom: 20px;
          `}
        />
        <LoadingLine
          color={backgroundColor}
          widthRem={25}
          randomizeWidthBy={5}
          count={2}
          heightPx={14}
          className={css`
            margin-bottom: 0.5rem;
          `}
        />
      </MaxWidth>
    </CategoryHeaderWrapper>
  );
});
